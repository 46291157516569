import { Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import apiClient from '../../service/ApiClient';
import { useAppDispatch, useAppSelector } from '../../store';
import { Dictionary } from '../../types/Common';
import { State } from '../../types/State';
import dictionariesActions from './../../store/Dictionaries';

export default function DicViewPanel(props: { dictionary: Dictionary, className: string }) {
    const auth = useAppSelector((state: State) => state.auth);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    function onStudyNowClick() {
        if (auth.user) {
            const body = { dictionaryId: props.dictionary.id, isDirect: true };
            apiClient.post(`api/study/settings/mode`, body)
                .then(() => {
                    navigate("/");
                })
        }
        else {
            dispatch(dictionariesActions.select(props.dictionary.id));
            navigate("/");
        }
    }

    return (
        <>
            <div className={props.className} >
                <div className="btn-panel">
                    <Button size="sm"
                        variant="outline-primary"
                        className="btn-wide btn-top-panel"
                        onClick={onStudyNowClick}>
                        Study it now
                    </Button>

                    {auth.user &&
                        <Link to={`/user/${auth.user?.id}/dictionary/${props.dictionary.id}/results`}
                            className="btn btn-outline-primary btn-sm btn-wide btn-top-panel px-1">
                            Studied cards
                        </Link>}

                    {(auth.isModerator || auth.user?.id === props.dictionary.authorId) &&
                        <div className="d-inline-block">
                            <Link to={`/cards/add/${props.dictionary.id}`}
                                className="btn btn-outline-primary btn-sm btn-wide btn-top-panel">
                                Add cards
                            </Link>

                            <Link to={`/cards/marked/${props.dictionary.id}`}
                                className="btn btn-outline-primary btn-sm btn-wide btn-top-panel"
                                state={props.dictionary} >
                                Marked cards
                            </Link>
                        </div>
                    }

                </div>
            </div>
        </>
    );
}
