import * as React from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';

export default function SearchPanel(props: {
    text?: string,
    full?: boolean,
    onSearch: (x: string, y: boolean) => void,
    className?: string,
    autoFocus?: boolean
}) {
    const [text, setText] = React.useState(props.text ?? '');
    const [full, setFull] = React.useState(props.full ?? false);

    function onTextChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setText(value);
    }

    function onFullChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.checked;
        setFull(value);
    }

    function onSearch() {
        props.onSearch(text, full);
    }

    function onKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onSearch(text, full);
        }
    }

    return (
        <InputGroup
            className={`${props.className} mt-3`} >
            <FormControl
                placeholder="Search card text"
                aria-label="Card text"
                value={text}
                onChange={onTextChange}
                onKeyDown={onKeyDown}
                autoFocus={props.autoFocus}
            />
            <InputGroup.Text
                className="px-1 px-sm-2">
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox"
                        label="full"
                        onChange={onFullChange}
                        checked={full} />
                </Form.Group>
            </InputGroup.Text>
            <Button variant="primary"
                onClick={onSearch}
                disabled={!text}
                className="px-1 px-sm-2 pb-2">
                Search
            </Button>
        </InputGroup>
    );
}