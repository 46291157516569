import * as React from "react";
import { Button, ButtonProps } from 'react-bootstrap';

interface ProgressButtonProps extends ButtonProps {
    success: boolean | undefined,
    text?: string,
    successText?: string,
}

export default function ProgressButton(props: ProgressButtonProps) {
    return (
        <Button variant={props.variant ?? 'primary'}
            className={props.className}
            onClick={props.onClick}
            disabled={props.active || props.disabled}
            type={props.type ?? 'button'}
            size={props.size}>
            {props.active &&
                <span className="spinner-border spinner-border-sm me-2" />
            }
            {props.success ?
                <><span className="me-2 fw-bold" >✓</span> {props.successText ?? 'Saved'}</>
                :
                <>{props.text ?? 'Save'}</>
            }
        </Button>
    )
}
