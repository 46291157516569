import { ColumnDef } from '@tanstack/react-table';
import { Button, Dropdown } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useFetchOperation } from '../../../service/Operation';
import { useAppSelector } from '../../../store';
import { UserMode } from '../../../types/Common';
import { LocalDate, apiDateFormat } from '../../formatters/DateTimeFormat';
import AutoPaginationTable from '../../general/AutoPaginationTable';
import Error from '../../general/Error';
import Loader from '../../general/Loader';
import UserPanel from './../UserPanel';
import * as moment from 'moment';
import * as React from 'react';

interface UserHistoryPage {
    userMode: UserMode,
    dailyHistory: DayStat[],
    weeklyHistory: DayStat[],
    monthlyHistory: DayStat[],
    periodsHistory: DayStat[]
}

interface DayStat {
    date: string,
    period: number | undefined,

    reviews: number | undefined,
    studied: number,
    memorized: number | undefined,
    notMemorized: number | undefined,
    weak: number | undefined,
    medium: number | undefined,
    strong: number | undefined,
    onHold: number | undefined,
    notStrong: number | undefined,
    lastUpdated: number,

    avgStrength: number | undefined,
    weakDeltaSum: number | undefined,
    interpretShare: number | undefined,
    memorizedPercent: number | undefined,

    avgStrengthDelta: number | undefined,
    memorizedDelta: number | undefined,
    studiedDelta: number | undefined,
    reviewsDelta: number | undefined,
    lastUpdatedDelta: number,
}

export const historyPeriods: { [code: number]: string } = {
    0: 'Now',
    1: 'Today',
    2: '2 days',
    3: '3 days',
    4: '4 days',
    5: '5 days',
    6: '6 days',
    7: '1 week',
    14: '2 weeks',
    21: '3 weeks',
    30: '1 month',
    46: '1.5 months',
    61: '2 months',
    91: '3 months',
    122: '4 months',
    152: '5 months',
    183: '6 months',
    213: '7 months',
    244: '8 months',
    274: '9 months',
    304: '10 months',
    335: '11 months',
    365: '1 year',
    548: '1.5 years',
    731: '2 years',
    913: '2.5 years',
    1096: '3 years',
    1278: '3.5 years',
    1461: '4 years',
    1643: '4.5 years',
    1826: '5 years',
    2009: '5.5 years',
    2192: '6 years',
    2374: '6.6 years',
    2557: '7 years',
    2739: '7.5 years',
    2922: '8 years',
}

const breakdowns = {
    days: 'Daily',
    weeks: 'Weekly',
    months: 'Monthly',
    //periods: 'Periods',
}

type Breakdown = keyof typeof breakdowns;

const defaultBreakdown: Breakdown = Object.keys(breakdowns)[0] as Breakdown;

export default function UserHistory() {
    const auth = useAppSelector(state => state.auth);
    const params = useParams();
    const contanerRef = React.useRef(null);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    let initBreakdown = searchParams.get("breakdown")?.toLowerCase() as Breakdown;
    if (!breakdowns[initBreakdown])
        initBreakdown = defaultBreakdown;

    const [breakdown, setBreakdown] = React.useState<Breakdown>(initBreakdown as Breakdown);
    const [pageData, setPageData] = React.useState<UserHistoryPage>();
    const [history, setHistory] = React.useState<DayStat[]>();
    const [updationsMaxValue, setUpdationsMaxValue] = React.useState<number>(100);

    const [chartExpandable, setChartExpandable] = React.useState(false);
    const [chartExpanded, setChartExpanded] = React.useState(false);
    const [expandedChartWidth, setExpandedChartWidth] = React.useState(100);

    const [getting, startGetting] = useFetchOperation(onGetSuccess, undefined, true);
    const darkTheme = window.matchMedia("(prefers-color-scheme: dark)")?.matches;

    const scrollRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const isDirect = searchParams.get('direct')?.toLowerCase() !== 'false';
        let url = `api/user/${params.id}/history?dictionaryId=${params.dictionaryId}&isDirect=${isDirect}`;
        if (breakdown !== defaultBreakdown)
            url += '&breakdown=' + breakdown;
        startGetting('get', url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        let newBreakdown = searchParams.get("breakdown")?.toLowerCase() as Breakdown;
        if (!breakdowns[newBreakdown])
            newBreakdown = defaultBreakdown;
        setBreakdown(newBreakdown)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    function onGetSuccess(data: UserHistoryPage) {
        setPageData(data);
        updateData(breakdown, data);

        document.title = `${data.userMode.userName} - ${data.userMode.dictionaryName} ${data.userMode.isDirect ? '' : '(inverse) '}- History - ${(document as any).rootTitle}`;
    }

    function onBreakdownChange(breakdown: Breakdown) {
        setBreakdown(breakdown);
        updateData(breakdown, pageData!);

        let sp = Array.from(searchParams).filter(([key, value]) => ['dictionary', 'direct'].includes(key));
        if (breakdown !== defaultBreakdown)
            sp.push(['breakdown', breakdown]);
        sp = sp.concat(Array.from(searchParams).filter(([key, value]) => ['sort', 'dir'].includes(key)));
        setSearchParams(sp);
    }

    function updateData(breakdown: Breakdown, data: UserHistoryPage) {
        let newHistory: DayStat[] | undefined = data.dailyHistory;

        if (breakdown === 'weeks')
            newHistory = data.weeklyHistory;
        if (breakdown === 'months')
            newHistory = data.monthlyHistory;

        let expandedWidth: number | undefined = undefined;
        if (newHistory && newHistory?.length) {
            let days = Math.abs(new Date(newHistory[newHistory.length - 1].date).getTime() - new Date(newHistory[1].date).getTime()) / (1000 * 60 * 60 * 24);
            if (breakdown === 'weeks')
                days /= 3;
            else if (breakdown === 'months')
                days /= 50;
            expandedWidth = Math.floor(days * 3);
            setExpandedChartWidth(expandedWidth);
        }
        const expandable = expandedWidth! > (contanerRef.current as any)?.offsetWidth;
        setChartExpandable(expandable);
        setHistory(newHistory);

        let max = 100;
        for (let i = 0; i < newHistory.length; i++) {
            if (typeof newHistory[i].reviewsDelta === 'number' && newHistory[i].reviewsDelta! > max)
                max = newHistory[i].reviewsDelta!;
        }
        setUpdationsMaxValue(max);
    }

    function expandChart() {
        setChartExpanded(!chartExpanded);
        setTimeout(function () {
            if (scrollRef.current)
                scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
        }, 1);
    }

    const options: any = React.useMemo(() => ({
        hAxis: {
            titleTextStyle: { italic: false },
            textStyle: { fontSize: 14 }
        },
        vAxes: [
            {
                title: "Cards",
                titleTextStyle: { italic: false, fontSize: 16 },
                minValue: 0,
                textStyle: { fontSize: 14 }
            },
            {
                maxValue: 1,
                minValue: 0,
                gridlines: { count: 0 },
                textPosition: 'none',
                textStyle: { fontSize: 14 }
            },
            {
                title: "Updations",
                titleTextStyle: { italic: false, fontSize: 16 },
                gridlines: { count: 0 },
                textStyle: { fontSize: 14 },
                minValue: 0,
                maxValue: updationsMaxValue,
            },
            {
                minValue: 0,
                maxValue: updationsMaxValue,
                gridlines: { count: 0 },
                textPosition: 'none',
            },
        ],
        bar: { groupWidth: '80%' },
        isStacked: true,
        legend: { position: 'bottom', textStyle: { fontSize: 14 } },
        fontName: 'Segoe UI',
        chartArea: { bottom: 50, top: 8, left: 60, right: 60 },
        seriesType: 'line',
        series: {
            0: { type: 'bars', targetAxisIndex: 2 },
            1: { type: 'bars', targetAxisIndex: 3 },
            2: { targetAxisIndex: 1, lineDashStyle: [2, 2] },
            3: { targetAxisIndex: 1, lineDashStyle: [2, 2] },
            4: { lineDashStyle: [6, 3] },
            5: { lineDashStyle: [6, 3] },
        },
        colors: ['#aaa', '#333', '#888', '#ffaaaa', '#009a00', '#00e3ff', '#ff6363', '#ff8200', '#c300c3', '#3366cc'],
        //      rev-del l-u-delta share   strength   memorized  not-memor  weak       not-strong strong     studied 
        //dataOpacity: 0.1,
        backgroundColor: darkTheme ? '#1d1d1d' : 'white',
        height: '450px',
        width: chartExpanded && expandedChartWidth ? expandedChartWidth + 'px' : '100%'
    }), [darkTheme, chartExpanded, updationsMaxValue, expandedChartWidth]);

    const isDirect = searchParams.get('direct')?.toLowerCase() !== 'false';
    let search = '';
    if (!isDirect)
        search += `&direct=false`

    const columns = React.useMemo<ColumnDef<DayStat>[]>(() => [
        {
            header: 'Date',
            accessorKey: 'date',
            cell: props => typeof props.row.original.period === 'number'
                ? historyPeriods[props.row.original.period]
                : auth.isAdmin
                    ? <Link to={`${moment.utc(props.row.original.date).format(apiDateFormat)}?${search}`}><LocalDate value={props.row.original.date} /></Link>
                    : props.row.original.date
        },
        {
            header: 'In studying',
            accessorKey: 'studied',
            cell: (props) => <span>{props.row.original.studied}{(typeof (props.row.original.studiedDelta) == 'number') ? ' (' + (props.row.original.studiedDelta > 0 ? '+' : '') + props.row.original.studiedDelta + ')' : ''}</span>
        },
        {
            header: 'Memorized',
            accessorKey: 'memorized',
            cell: (props) => (typeof (props.row.original.memorized) == 'number') &&
                <span>{props.row.original.memorized!}&thinsp;{(typeof (props.row.original.memorizedDelta) == 'number') ? '(' + (props.row.original.memorizedDelta! > 0 ? '+' : '') + props.row.original.memorizedDelta! + ') ' : '• '}{props.row.original.memorizedPercent?.toFixed()}%</span>
        },
        {
            header: 'Reviews',
            accessorKey: 'updations',
            cell: props => (typeof (props.row.original.reviews) == 'number') &&
                <span>{props.row.original.reviews!} {(typeof (props.row.original.reviewsDelta) == 'number') ? '(' + (props.row.original.reviewsDelta! > 0 ? '+' : '') + props.row.original.reviewsDelta! + ') ' : ''}</span>
        },
        {
            header: 'Average strength',
            accessorKey: 'avgStrength',
            cell: props => (typeof (props.row.original.avgStrength) == 'number') &&
                <span>{props.row.original.avgStrength?.toFixed(4)} {props.row.original.avgStrengthDelta! ? '(' + (props.row.original.avgStrengthDelta! > 0 ? '+' : '') + props.row.original.avgStrengthDelta?.toFixed(4) + ')' : ''}</span>
        },
        {
            header: 'Strong+​medium+​​weak+on hold',
            accessorKey: 'strong',
            cell: props => (typeof (props.row.original.strong) == 'number') &&
                <span>{props.row.original.strong!}+{props.row.original.medium!}+{props.row.original.weak!} ({props.row.original.weakDeltaSum?.toFixed(0)})+{props.row.original.onHold!}</span>
        },
        {
            header: 'Study : Choice',
            accessorKey: 'interpretShare',
            cell: props => (typeof (props.row.original.interpretShare) == 'number')
                ? <span>{(props.row.original.interpretShare * 100).toFixed(1)}%&thinsp;:&thinsp;{((1 - props.row.original.interpretShare) * 100).toFixed(1)}%</span>
                : <span></span>
        },
        {
            header: 'last updated',
            accessorKey: "lastUpdated"
        },
        {
            header: 'last updated delta',
            accessorKey: "lastUpdatedDelta"
        }
    ], []);

    return (
        <>
            <div ref={contanerRef} className="w-100 d-flex flex-column"></div>

            <UserPanel userMode={pageData?.userMode} />

            {getting.active ?
                <Loader />
                :
                getting.error ?
                    <Error text={getting.error} />
                    :
                    !history ?
                        <p className="my-3">No results found</p>
                        :
                        <div className="w-100">
                            <h2 className="mt-3 mb-1">Timeline
                                {chartExpandable &&
                                    <Button size="sm"
                                        variant="outline-secondary"
                                        onClick={expandChart}
                                        className="ms-3">
                                        {chartExpanded ? 'Collapse' : 'Expand'}
                                    </Button>
                                }
                            </h2>

                            <div style={{ overflowY: 'hidden', overflowX: 'auto', width: '100%', minHeight: '450px', scrollBehavior: 'smooth' }}
                                ref={scrollRef} >

                                <Chart key={chartExpanded ? 1 : 0}
                                    chartType="ComboChart"
                                    data={[['date', 'Reviews', 'Last updated cards', 'Study:Choice', 'Strength', 'Memorized', 'Not memorized', 'Weak', 'Not strong', 'Strong', 'In studying'], ...history.map(x =>
                                        [
                                            new Date(x.date).getTime() < 0 ? new Date() : new Date(x.date),

                                            x.reviewsDelta,
                                            x.lastUpdatedDelta,
                                            x.interpretShare,
                                            x.avgStrength,
                                            x.memorized,
                                            x.notMemorized,
                                            x.weak,
                                            x.notStrong,
                                            x.strong,
                                            x.studied,
                                        ])]}
                                    options={options} />
                            </div>


                            <div className="mt-2 mb-3">
                                <Dropdown className="mt-2 d-inline-block">

                                    <Dropdown.Toggle
                                        className="btn-wide" >
                                        {breakdowns[breakdown]}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {Object.entries(breakdowns).map(([key, value]) =>
                                            <Dropdown.Item as={Button}
                                                variant="outline"
                                                key={key}
                                                active={key === breakdown}
                                                onClick={() => onBreakdownChange(key as Breakdown)}>
                                                {value}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <span className="ms-2">{history.length - 1} {breakdown} in the history</span>
                            </div>

                            <AutoPaginationTable className="mt-2 mb-3"
                                columns={columns}
                                data={history} />
                        </div>
            }
        </>
    )
}
