import { ModeStat } from '../../../types/StatTypes';

export default function ModeStatBox(props: { modeStat: ModeStat }) {
    const modeStat = props.modeStat;

    return (
        <div className="d-flex justify-content-center my-3">
            <table className="d-block p-3 bg-light border border-primary rounded-3">
                <tbody>
                    <tr>
                        <td>Total cards in the dictionary:</td>
                        <td>{modeStat.total}</td>
                    </tr>
                    <tr>
                        <td>Not skipped:</td>
                        <td>{modeStat.notSkipped}</td>
                    </tr>
                    <tr>
                        <td>In active studying:</td>
                        <td>{modeStat.studied}</td>
                    </tr>
                    <tr>
                        <td>Memorized:</td>
                        <td>{modeStat.memorized} ({modeStat.memorizedPercent.toFixed(1)}%)</td>
                    </tr>
                    <tr>
                        <td>Strong + medium + weak:</td>
                        <td>{modeStat.strong}+{modeStat.medium}+{modeStat.weak}</td>
                    </tr>
                    <tr>
                        <td className="pt-3">Ready to review:</td>
                        <td className="pt-3">{modeStat.ready}</td>
                    </tr>
                    <tr>
                        <td>Will be ready to review soon:</td>
                        <td>{modeStat.readySoon}</td>
                    </tr>
                    <tr>
                        <td>On hold:</td>
                        <td>{modeStat.onHold}</td>
                    </tr>
                    <tr>
                        <td className="pt-3">Average strength:</td>
                        <td className="pt-3">{modeStat.avgStrength.toFixed(4)}</td>
                    </tr>
                    <tr>
                        <td>Total number of reviews:</td>
                        <td>{modeStat.reviews}</td>
                    </tr>
                    <tr>
                        <td>Average shown for one card:</td>
                        <td>{modeStat.studied === 0 ? 0 : (modeStat.reviews / modeStat.studied).toFixed(1)}</td>
                    </tr>
                    <tr>
                        <td>Weight of weak cards:</td>
                        <td>{modeStat.weakDeltaSum.toFixed(1)}</td>
                    </tr>
                    <tr>
                        <td>Study to Choice:</td>
                        <td>{(100 * modeStat.interpretShare).toFixed(1)}%&thinsp;:&thinsp;{(100 * (1 - modeStat.interpretShare)).toFixed(1)}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
