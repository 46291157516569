import * as React from 'react';
import { Link } from 'react-router-dom';

export default function HoverDropDown(props: {
    className: string,
    titleLinkTo: string,
    titleText: string,
    children: React.ReactNode
}) {
    const [showDropdown, setShowDropdown] = React.useState(false);
    const isTouchDevice = "ontouchstart" in document.documentElement;
        
    function onMouseEnter() {
        if (!isTouchDevice)
            setShowDropdown(true);
    }
    function onMouseLeave() {
        if (!isTouchDevice)
            setShowDropdown(false);
    }
    function onBlur() {
        if (isTouchDevice)
            setTimeout(() => setShowDropdown(false), 10);
    }

    function onDropdownTitleClick() {
        setShowDropdown(isTouchDevice && !showDropdown);
    }

    function onMenuItemClick() {
        setShowDropdown(false);
    }

    return (
        <div className={props.className + " nav-item dropdown"}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onBlur={onBlur} >
            <Link to={isTouchDevice ? '' : props.titleLinkTo}
                className="nav-link dropdown-toggle"
                onClick={onDropdownTitleClick} >
                {props.titleText}
            </Link>
            {showDropdown &&
                <div className="dropdown-menu"
                    onClick={onMenuItemClick} >
                    {props.children}
                </div>
            }
        </div>
    )
}
