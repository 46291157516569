import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFetchOperation } from '../../service/Operation';
import { useAppDispatch } from '../../store';
import { authActionCreators } from './../../store/Auth';

export default function SignInGoogle() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code")
    const [error, setError] = React.useState('');
    const [, startSignIn] = useFetchOperation(onLoginSuccess, onLoginFail);

    React.useEffect(() => {

        startSignIn('post', '/api/auth/signin-google', { code: code })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])

    function onLoginSuccess(data: { token: string }) {
        dispatch(authActionCreators.setToken(data.token));
        navigate('/');
    }
    function onLoginFail(error: string) {
        console.log(error);
        setError(error);
    }

    return <div>
        Signing in...
        <br />
        <div>{error}</div>
    </div>
}
