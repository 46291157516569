import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '.';
import apiClient from '../service/ApiClient';
import { HistoryStat, ModeStat, UpdatedResult, ResultInfo, TrainingStat } from '../types/StatTypes';
import { CardsData } from '../types/TrainingTypes';

export interface StatState {
    isModeStatLoading: boolean,
    modeStat: ModeStat | undefined,
    previousModeStat: ModeStat | undefined,
    todayStat: HistoryStat | undefined,
    previousTodayStat: HistoryStat | undefined,
    yesterdayStat: HistoryStat | undefined,
    mixedReadyCount: number | undefined,
    lastResult: UpdatedResult | undefined,
    cardsInfo: string,
    cardsInfoUpdated: boolean,
    currentResultInfo: ResultInfo | undefined
}

const initialState: StatState = {
    isModeStatLoading: false,
    modeStat: undefined,
    previousModeStat: undefined,
    todayStat: undefined,
    previousTodayStat: undefined,
    yesterdayStat: undefined,
    mixedReadyCount: undefined,
    lastResult: undefined,
    cardsInfo: '',
    cardsInfoUpdated: false,
    currentResultInfo: undefined,
}

const slice = createSlice({
    name: 'stat',
    initialState,
    reducers: {
        modeStatStart: (state) => {
            state.isModeStatLoading = true;
        },

        modeStatSuccess: (state, action: PayloadAction<ModeStat>) => {
            state.isModeStatLoading = false;
            state.previousModeStat = state.modeStat;
            state.modeStat = action.payload;
            state.todayStat = undefined;
            state.yesterdayStat = undefined;
            state.mixedReadyCount = undefined;
            state.lastResult = undefined;
            state.cardsInfo = '';
            state.currentResultInfo = undefined;
        },

        modeStatFail: state => {
            state.isModeStatLoading = false;
        },

        setTrainingStat: (state, action: PayloadAction<TrainingStat>) => {
            state.previousModeStat = state.modeStat;
            state.previousTodayStat = state.todayStat;
            let readyDiff = 0;
            if (state.modeStat && action.payload
                && state.previousModeStat?.dictionaryId === action.payload.modeStat.dictionaryId
                && state.previousModeStat?.isDirect === action.payload.modeStat.isDirect) {
                readyDiff = action.payload.modeStat.ready - (state.previousModeStat.ready);
            }
            state.mixedReadyCount = action.payload?.mixedReadyCount ?? ((state.mixedReadyCount ?? 0) + readyDiff);
            state.modeStat = action.payload.modeStat;
            state.lastResult = action.payload.lastResult;
            state.todayStat = action.payload.todayStat;
            state.yesterdayStat = action.payload.yesterdayStat;
        },

        resetNewCardsInfo: state => {
            state.cardsInfoUpdated = false;
        },

        setCurrentResultInfo: (state, action: PayloadAction<ResultInfo>) => {
            state.currentResultInfo = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase('auth/logout', (state, action) => {
            return initialState;
        });

        builder.addCase('training/addCards', (state, action) => {
            const data = action as PayloadAction<CardsData>;
            state.cardsInfo = data.payload.cardsInfo;
            state.cardsInfoUpdated = true;
        });
    }
})

const { modeStatStart, modeStatSuccess, modeStatFail, setTrainingStat, resetNewCardsInfo, setCurrentResultInfo } = slice.actions;

const getModeStat = () => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(modeStatStart());
    apiClient.get('/api/stat/current-mode-stat')
        .then((data: ModeStat) => {
            dispatch(modeStatSuccess(data));
        })
        .catch(() => {
            dispatch(modeStatFail())
        });
}

export const statReducer = slice.reducer;

export const statActionCreators = {
    getModeStat,
    setTrainingStat,
    resetNewCardsInfo,
    setCurrentResultInfo,
}
