import { Card, CardSlim, DictionarySlim } from "./Common"
import { TrainingStat } from "./StatTypes"

export enum TrainingType {
    Auto,
    Interpret,
    Choice,
    Combined,
}

export enum ServicePanelVisibility {
    None,
    Interpret,
    InterpretAndCombined,
    Always,
    InterpretAndOnMistake,
}

export enum TaskKind {
    BothAlways,
    BothOnCombinedAndInterpret,
    BothOnInterpret,
    BothOnRevealed,
    TaskOnly,
    AuxOnly,
}

export interface SimilarCard extends Card {
    similar?: CardSlim[] | undefined
}

export interface TrainingCardData {
    card: TrainingCard | undefined,
    isSolutionShown: boolean,
}

export interface Variant {
    content: string,
    contentAux: string,
    wrongVariant: string,
    card: TrainingCard,
    width: number
}

export enum InterpretValue {
    DoNotKnow = 0,
    NotSure,
    Know,
    KnowWell,
    Reset,
    MinimalDecrease,
    MinimalIncrease,
    KnowExcellent
}
export enum ChoiceValue {
    Incorrect = 0,
    Correct,
    Decrease,
    Know
}

//response

export interface TrainingResponse {
    cardsData: CardsData,
    trainingStat: TrainingStat
}

export interface CardsData {
    interpretCards: TrainingCard[],
    choiceCards: TrainingCard[],
    combinedCards: TrainingCard[],
    modeInfo: ModeInfo,
    cardsInfo: string,
}

export interface TrainingCard extends Card {
    variants: TrainingCard[] | undefined,
    similar: SimilarCardSlim[] | undefined,
    trainingType: TrainingType,
    isPreview: boolean,
    modeInfo: ModeInfo,
    resultInfo: ResultInfo,
}

export interface SimilarCardSlim extends CardSlim {
    dictionaryName: string | undefined
}

export interface ModeInfo {
    dictionaryId: number,
    dictionaryName: string,
    isDirect: boolean,
    isPreview: boolean,
    isHard: boolean,
    isEditable: boolean,
    reversoPath: string | undefined,
    challengeLanguageCode: string | undefined,
    answerLanguageCode: string | undefined,
    wiktionaryLanguage: string | undefined,
    isInMix: boolean,
    onlyChallengeAuxAvailable: boolean,
    modeSetting: ModeSettingSlim,
}

export interface ResultInfo {
    id: number,
    info: string
}
//end response

export interface UserModesSettings {
    mixedActive: boolean,
    single: ModeSetting,
    mixed: ModeSetting[] | undefined,
    mixedModesLimit: number,
    dictionaries: DictionarySlim[]
}

export interface ModeSetting extends ModeSettingSlim {
    //userId: number,
    dictionaryId: number,
    isDirect: boolean,
    dictionaryName: string,
}

export interface ModeSettingSlim {
    studyLimit: number,
    randomOrder: boolean,
    newCardsQuantity: number,
    taskKind: TaskKind
}