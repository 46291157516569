import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { choiceActionCreators } from '../../store/Choice';
import { trainingActionCreators } from '../../store/Training';
import { ChoiceValue, Variant } from '../../types/TrainingTypes';
import ChoiceVariants from './ChoiceVariants';

export default function Choice() {
    const trainingState = useAppSelector(state => state.training);
    const state = useAppSelector(state => state.choice);
    const dispatch = useAppDispatch();
    const [isTimerActive, setIsTimerActive] = React.useState(false);

    function nextCard() {
        dispatch(trainingActionCreators.nextCard());
    }

    function chooseVariant(variant: Variant | undefined, isLong: boolean) {
        if (!isTimerActive) {
            const isCorrect = variant?.content === state.solution;
            if (trainingState.isSolutionShown) {
                if (variant?.content === state.solution || !trainingState.isServicePanelEnabled)
                    nextCard();
            }
            else {
                if (isCorrect && !trainingState.isServicePanelEnabled) {
                    setIsTimerActive(true);
                    setTimeout(() => {
                        setIsTimerActive(false);
                        nextCard();
                    }, 500);
                }
                dispatch(choiceActionCreators.chooseVariant(variant));

                const assessmentValue = isCorrect
                    ? isLong
                        ? ChoiceValue.Know
                        : ChoiceValue.Correct
                    : isLong
                        ? ChoiceValue.Decrease
                        : ChoiceValue.Incorrect;
                const time = new Date().getTime() - new Date(state.shownTime!).getTime();

                let difficulty = Math.min(20, (6 + state.task.length));
                let correctVariantPassed = false;
                for (let i = 0; i < state.variants.length; i++) {
                    difficulty += (Math.min(20, (6 + state.variants[i].content.length)) * (correctVariantPassed ? 0.6 : 1));
                    correctVariantPassed ||= (state.variants[i].content === variant?.content);
                }
                dispatch(trainingActionCreators.choiceAssess(assessmentValue, time, Math.round(difficulty)));
            }
        }
    }

    function resetTimer() {
        dispatch(choiceActionCreators.resetTimer());
    }

    return (
        <div className="flex-grow-1 w-100 width-limit text-break">
            <div style={{ minHeight: '5.5rem' }}>
                <div className="task-box mt-3"
                    onDoubleClick={resetTimer}>
                    <span>{state.task}</span>
                    {state.taskAux
                        && <span className="fs-2 text-secondary"> {state.taskAux}</span>
                    }
                </div>
            </div>

            <ChoiceVariants variants={state.variants}
                backgrounds={state.backgrounds}
                chooseVariant={chooseVariant} />

            {state.wrongVariant &&
                <div className="fs-5"><span className="red">{state.wrongVariant.content}</span> - <span className="fw-bold">{state.wrongVariant.wrongVariant}</span></div>
            }
            {trainingState.isSolutionShown && trainingState.isServicePanelEnabled && trainingState.card?.example &&
                <div className="task-box mt-3">
                    <div className="text-secondary">{trainingState.card.example}</div>
                </div>
            }
        </div>
    );
}
