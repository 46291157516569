import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '.';
import apiClient from '../service/ApiClient';
import { Card, Mode } from '../types/Common';
import { CardsData, ChoiceValue, InterpretValue, ModeSettingSlim, ServicePanelVisibility, TrainingCard, TrainingCardData, TrainingResponse, TrainingType } from '../types/TrainingTypes';
import { statActionCreators } from './Stat';
import { interpretActionCreators } from './Interpret';
import { choiceActionCreators } from './Choice';
import { combinedActionCreators } from './Combined';

interface TrainingState {
    cards: TrainingCard[],
    card: TrainingCard | undefined,
    trainingType: TrainingType | undefined,
    isPreview: boolean | undefined,
    isHard: boolean | undefined,
    modeId: number | undefined,
    isSolutionShown: boolean,
    isMistake: boolean,
    servicePanelVisibility: ServicePanelVisibility,
    isServicePanelEnabled: boolean,
}

interface Assessment {
    cardId: number,
    isDirect: boolean,
    isPreview: boolean
}

interface InterpretAssessment extends Assessment {
    value: InterpretValue
}

interface ChoiceAssessment extends Assessment {
    value: ChoiceValue,
    time: number,
    difficulty: number,
}

interface Skip extends Assessment { }

const servicePanelVisibilityKey = 'servicePanelVisibilityKey';

const defaultServicePanelVisibility = ServicePanelVisibility.Interpret;

const getServicePanelVisibility = () => {
    let value = localStorage.getItem(servicePanelVisibilityKey);
    if (value)
        return parseInt(value) as ServicePanelVisibility;
    else
        return defaultServicePanelVisibility
}

const initialConstState = {
    cards: [],
    card: undefined,
    trainingType: undefined,
    isPreview: undefined,
    isHard: undefined,
    modeId: undefined,
    isSolutionShown: false,
    isMistake: false,
    isServicePanelEnabled: true,
}

const initialState: TrainingState = {
    ...initialConstState,
    servicePanelVisibility: getServicePanelVisibility(),
}

const slice = createSlice({
    name: 'training',
    initialState,
    reducers: {
        reset: (state) => { return { ...state, ...initialConstState } },

        setTraining: (state, action: PayloadAction<{ trainingType: TrainingType, isPreview: boolean, isHard: boolean }>) => {
            state.trainingType = action.payload.trainingType;
            state.isPreview = action.payload.isPreview;
            state.isHard = action.payload.isHard;
        },

        addCards: (state, action: PayloadAction<CardsData>) => {
            const cardsData = action.payload;
            const sets: [TrainingCard[], TrainingType][] = [
                [cardsData.choiceCards, TrainingType.Choice],
                [cardsData.combinedCards, TrainingType.Combined],
                [cardsData.interpretCards, TrainingType.Interpret]
            ];
            sets.forEach(s => {
                s[0]?.forEach(x => {
                    if (!state.cards.find(y => y.id === x.id) && state.card?.id !== x.id) {
                        x.trainingType = s[1];
                        x.modeInfo = cardsData.modeInfo;
                        state.cards.push(x);
                    }
                })
            });
        },

        nextCard: (state) => {
            const newCards = [...state.cards];
            const card = newCards.shift();

            state.cards = newCards;
            state.card = card;
            state.isSolutionShown = false;
            state.isMistake = false;
            state.modeId = card ? card.modeInfo.isInMix ? 0 : (card.modeInfo.isDirect ? 1 : -1) * card.modeInfo.dictionaryId : undefined;
            updateIsServicePanelEnabled(state);
        },

        showSolution: (state, action: PayloadAction<boolean>) => {
            state.isSolutionShown = true;
            state.isMistake = action.payload;
            updateIsServicePanelEnabled(state);
        },

        refreshCardData: (state, action: PayloadAction<TrainingCardData>) => {
            state.card = action.payload.card;
            state.isSolutionShown = action.payload.isSolutionShown;
        },

        refreshModeSetting: (state, action: PayloadAction<ModeSettingSlim>) => {
            if (state.card) {
                state.card.modeInfo.modeSetting = action.payload;
            }
        },

        setServicePanelVisibility: (state, action: PayloadAction<ServicePanelVisibility>) => {
            state.servicePanelVisibility = action.payload;
            updateIsServicePanelEnabled(state);
        },
    },
    extraReducers: (builder) => {
        builder.addCase('mode/changeSuccess', (state, action) => {
            const mode = (action as PayloadAction<Mode>).payload;
            const modeId = (mode.isDirect ? 1 : -1) * mode.dictionaryId;
            if (state.modeId !== modeId)
                return { ...state, ...initialConstState };
        });
    }
})

const updateIsServicePanelEnabled = (state: TrainingState) => {
    const trainingType = state.card?.trainingType;

    if (state.servicePanelVisibility === ServicePanelVisibility.InterpretAndOnMistake)
        state.isServicePanelEnabled = trainingType === TrainingType.Interpret || state.isMistake;
    else {
        let minSpValue = ServicePanelVisibility.None;
        if (trainingType === TrainingType.Interpret)
            minSpValue = ServicePanelVisibility.Interpret;
        else if (trainingType === TrainingType.Combined)
            minSpValue = ServicePanelVisibility.InterpretAndCombined;
        else if (trainingType === TrainingType.Choice)
            minSpValue = ServicePanelVisibility.Always;
        state.isServicePanelEnabled = state.servicePanelVisibility >= minSpValue;
    }
}

const start = (trainingType: TrainingType, isPreview: boolean, isHard: boolean, guestDictionaryId: number) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        if (state.training.trainingType !== trainingType
            || state.training.isPreview !== isPreview
            || state.training.isHard !== isHard
            || (!state.auth.user && state.dictionaries.dictionaryId !== guestDictionaryId)) {
            dispatch(slice.actions.reset());
            dispatch(slice.actions.setTraining({ trainingType, isPreview, isHard }));
            dispatch(request(undefined, undefined, undefined));
        }
    }
}

const interpretAssess = (assessmentValue: InterpretValue) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState().training;
        const assessment = state.card!.modeInfo.isHard ? undefined : {
            cardId: state.card!.id,
            isDirect: state.card!.modeInfo.isDirect,
            isPreview: state.card!.modeInfo.isPreview,
            value: assessmentValue
        };
        dispatch(request(assessment, undefined, undefined));
    };
};

const choiceAssess = (assessmentValue: ChoiceValue, assessmentTime: number, difficulty: number) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState().training;
        const assessment = state.card!.modeInfo.isHard ? undefined : {
            cardId: state.card!.id,
            isDirect: state.card!.modeInfo.isDirect,
            isPreview: state.card!.modeInfo.isPreview,
            value: assessmentValue,
            time: assessmentTime,
            difficulty: difficulty
        };
        dispatch(request(undefined, assessment, undefined));
    };
};

const skip = () => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState().training;
        const skip: Skip = {
            cardId: state.card!.id,
            isDirect: state.card!.modeInfo.isDirect,
            isPreview: false
        };
        dispatch(request(undefined, undefined, skip));
    };
};

const request = (
    interpretAssessment: InterpretAssessment | undefined,
    choiceAssessment: ChoiceAssessment | undefined,
    skip: Assessment | undefined) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState().training;
        const auth = getState().auth;
        const needCards = state.cards.length <= 2;
        if (needCards || interpretAssessment || choiceAssessment || skip) {
            const body: any = {};
            if (interpretAssessment)
                body.interpretAssessment = interpretAssessment;
            if (choiceAssessment)
                body.choiceAssessment = choiceAssessment;
            if (skip)
                body.skip = skip;
            if (needCards) {
                if (auth.user) {
                    body.cardsRequest = {
                        type: state.trainingType,
                        isPreview: state.isPreview,
                        isHard: state.isHard,
                        queueCardIds: state.cards.map(x => x.id)
                    }
                    if (!state.isHard && !state.isPreview)
                        body.queueCardIds = state.cards.map(x => x.id);
                }
                else {
                    body.dictionaryId = getState().dictionaries.dictionaryId;
                    body.cardsRequestType = state.trainingType;
                }
            }
            if (!auth.user)
                body.sessionId = getSessionId();

            const url = auth.user ? 'api/study' : 'api/study/guest';
            apiClient.post(url, body)
                .then((data: TrainingResponse) => {
                    if (data.cardsData?.interpretCards || data.cardsData?.choiceCards || data.cardsData?.combinedCards)
                        dispatch(slice.actions.addCards(data.cardsData));
                    else
                        dispatch(statActionCreators.resetNewCardsInfo());

                    if (data.trainingStat)
                        dispatch(statActionCreators.setTrainingStat(data.trainingStat));

                    if (!getState().training.card || skip)
                        dispatch(nextCard());
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
}

const getSessionId = () => {
    const resultsSessionKey = 'resultsSessionKey';
    let sessionId = localStorage.getItem(resultsSessionKey);
    if (!sessionId) {
        sessionId = Math.random().toString().substring(2);
        localStorage.setItem(resultsSessionKey, sessionId);
    }
    return sessionId;
}

const nextCard = () => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(slice.actions.nextCard());

        const state = getState().training;
        if (state.card) {
            switch (state.card.trainingType) {
                case TrainingType.Interpret:
                    dispatch(interpretActionCreators.setNextCard(state.card));
                    break;
                case TrainingType.Choice:
                    dispatch(choiceActionCreators.setNextCard(state.card));
                    break;
                case TrainingType.Combined:
                    dispatch(combinedActionCreators.setNextCard(state.card));
                    break;
            }
            const currentResultInfo = state.card.resultInfo ?? { id: state.card.id }
            dispatch(statActionCreators.setCurrentResultInfo(currentResultInfo));
        }
    }
}

const setModeSetting = (modeSetting: ModeSettingSlim) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState().training;
        if (state.card?.modeInfo.modeSetting.taskKind !== modeSetting.taskKind)
            dispatch(slice.actions.refreshCardData({
                card: state.card,
                isSolutionShown: state.isSolutionShown,
            }));
        dispatch(slice.actions.refreshModeSetting(modeSetting));
    }
}

const setServicePanelVisibility = (servicePanelVisibility: ServicePanelVisibility) => {
    localStorage.setItem(servicePanelVisibilityKey, (servicePanelVisibility as number).toString());

    return slice.actions.setServicePanelVisibility(servicePanelVisibility);
}

const refreshCard = (card: TrainingCard | Card) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState().training;
        if (state.card && state.card.id === card.id) {
            dispatch(slice.actions.refreshCardData({
                card: { ...state.card, ...card },
                isSolutionShown: state.isSolutionShown
            }));
        }
    }
}

export const trainingReducer = slice.reducer;

export const trainingActionCreators = {
    start,
    interpretAssess,
    choiceAssess,
    skip,
    nextCard,
    showSolution: slice.actions.showSolution,
    refreshCard,
    setModeSetting,
    setServicePanelVisibility
}
